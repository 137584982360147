<template>
	<div>
		<CCard>
			<CCardHeader>
				<h3 class="center-text">
					404 - Pagina no Encontrada
				</h3></CCardHeader
			>
			<CCardBody>
				<!-- <CRow>
					<CCol sm="12" class="center-text">
						<img
							class="img-fluid"
							style="width: 40%;"
							:src="pageNotFoundImage"
							alt="no econtrado"
						/>
					</CCol>
				</CRow>
				<br /> -->
				<CRow>
					<CCol sm="5" class="center-text">
						<img
							class="img-fluid"
							width="70%"
							:src="pageNotFoundImage"
							alt="404"
						/>
					</CCol>
					<CCol sm="7" class="center-text">
						<CRow>
							<CCol sm="12" class="center-text">
								<p class="h5 justify-text">
									Parece que la p&aacute;gina a la que
									intentas acceder no existe.
								</p>
								<br />
								<p class="h5 justify-text">
									Puedes regresar al Home del sistema haciendo
									clic en el bot&oacute;n <b>Ir al Home</b>.
								</p>
								<br />
								<p class="h5 justify-text">
									Tambi&eacute;n puedes usar el men&uacute;
									lateral izquierdo para navegar por los
									diferentes m&oacute;dulos del sistema o usar
									los accesos r&aacute;pidos.
								</p>
							</CCol>
						</CRow>
						<CRow style="margin-top: 50px;">
							<CCol sm="12" class="center-text">
								<b-button
									@click="
										$router.push({
											name: 'Home',
										})
									"
									title="Home"
									id="goBack"
									size="lg"
									pill
									variant="info"
									class="mr-1 text-white"
								>
									Ir al home
								</b-button>
							</CCol>
						</CRow>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
	export default {
		name: 'pageNotFound',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				pageNotFoundImage: process.env.VUE_APP_NOT_FOUND_IMAGE,
			};
		},
		created() {},
	};
</script>

<style></style>
